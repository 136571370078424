<template>
  <van-popup
    v-model:show="showPopup"
    position="bottom"
    class="az-search__popup"
    close-icon-position="top-left"
    closeable
    safe-area-inset-bottom
    close-on-popstate
  >
    <div class="h-full flex flex-col">
      <div class="py-1.5 pl-10">
        <van-search
          v-model="searchInput"
          left-icon=""
          shape="round"
          :placeholder="t('key.search.destination.placeholder')"
          class="az-search"
        />
      </div>

      <div class="flex-1 overflow-y-auto">
        <van-divider style="margin-top: 0px" />
        <div v-if="isSearching">
          <template v-if="searchCityList.length">
            <lazy-component v-for="item in searchCityList" :key="item.destinationId">
              <div class="flex px-4 py-1.25" @click="onConfirm(item)">
                <div h="11" w="11" flex="~ items-center justify-center" rounded="xl" bg="#f6f6f6">
                  <img class="h-6 w-6" src="~assets/icons/icon_location.png" />
                </div>
                <div class="ml-2.5 flex flex-col justify-between py-0.5">
                  <div
                    class="text-base text-[#121212] font-500"
                    v-html="item.destinationNameHTML"
                  ></div>
                  <div class="text-xs text-[#B2B2B2]" v-html="item.countryNameHTML"></div>
                </div>
              </div>
            </lazy-component>
          </template>
          <div v-else class="pt-40 text-center text-base text-[#B2B2B2] font-500">
            {{ t('key.search.destination.emptyTips', [searchInput]) }}
          </div>
        </div>
        <template v-else>
          <van-skeleton :loading="loading">
            <template #template>
              <div class="w-full">
                <van-skeleton-title />
                <div class="my-4 flex">
                  <van-skeleton-image class="mr-4" />
                  <van-skeleton-image class="mr-4" />
                  <van-skeleton-image class="mr-4" />
                </div>
                <van-skeleton-title />
                <div v-for="(_, index) in Array.from({ length: 6 })" :key="index" class="my-4 flex">
                  <van-skeleton-title class="rounded-lg" style="height: 44px" title-width="44px" />
                  <div class="flex-1">
                    <van-skeleton :row="2" />
                  </div>
                </div>
              </div>
            </template>
            <div v-if="history.length" p="x-4 y-2">
              <div text="base #121212" font="500">
                {{ t('key.search.destination.history') }}
              </div>
              <div class="flex flex-wrap" p="t-3 b-1">
                <div v-for="item in history" :key="item.destinationId" class="cursor-pointer" text="sm #646464" p="y-2 x-3" m="r-3 b-2.5" font="500" bg="white" b="1 solid #777474" rounded="10" @click="onConfirm(item)">
                  {{ item.destinationName }}
                </div>
              </div>
            </div>
            <template v-if="cityInfo.recommendDestinationList?.length">
              <div class="px-4 pb-4 text-base text-[#121212] font-500">
                {{ t('key.search.destination.featured') }}
              </div>
              <div class="pr-6">
                <div class="flex flex-wrap gap-2.5" p="b-2 l-4">
                  <div
                    v-for="item in cityInfo.recommendDestinationList"
                    :key="item.destinationId"
                    w="24"
                    @click="onConfirm(item)"
                  >
                    <div border="~ 1 solid #E4E4E4" rounded="xl" p="1.5">
                      <van-image
                        :src="item.img"
                        :radius="8"
                        w="20"
                        h="20"
                        class="rounded-xl"
                      />
                      <div class="line-clamp-1 mt-1 text-sm text-black font-500">
                        {{ item.destinationName }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </van-skeleton>
        </template>
      </div>
    </div>
  </van-popup>
</template>

<script lang="ts" setup>
import type { AttrDestinationDto } from '~/apis/back/types'
import { ENUM_EventType, ENUM_PageName } from '~/enums'
import type { PartnerCityListRes } from '~/server/types/city.type'
import type { CityClicksClickEvent } from '~/types/event-tracking'

const props = defineProps<{ refrehsOnConfirm?: boolean, pageType?: string, loading: boolean }>()
const showPopup = defineModel({ default: false })
const { t } = useI18n()
const route = useRoute()
const gtm = useGtm()
const { locale } = useLocale()
const commonStore = useCommonStore()
const filterConditionStore = useFilterConditionsStore()
const { isSearching, searchCityList, searchInput, history, onConfirmCity } = useSearchCity({ refrehsOnConfirm: props.refrehsOnConfirm })

const cityInfo = computed<PartnerCityListRes>(
  () =>
    commonStore.commonData.cityInfo || {
      attrDestinationList: [],
      recommendDestinationList: [],
    },
)

function onConfirm(item: AttrDestinationDto) {
  showPopup.value = false

  onConfirmCity(item)
  trackCityClickEvent()
}

function trackCityClickEvent() {
  const params: CityClicksClickEvent = {
    event: ENUM_EventType.CityClick,
    page_category: props.pageType === 'home' ? ENUM_PageName.Home : ENUM_PageName.List,
    locale: locale.value,
    city_id: filterConditionStore.filterConditions.selectedCity?.destinationId,
    city: filterConditionStore.filterConditions.selectedCity?.destinationName,
    campaign_id: route.query.campaign_id as string ?? '',
    campaign_name: route.query.campaign_name as string ?? '',
    country_code: filterConditionStore.filterConditions.selectedCity?.countryCode,
  }
  gtm?.trackEvent(params)
}
watch(showPopup, (value) => {
  if (value) {
    searchInput.value = ''
  }
})
</script>

<style lang="scss" scoped>
.az-search__popup {
  height: 100%;
  :deep(.van-popup__close-icon) {
    top: 24px;
  }
}
.az-search {
  :v-deep(.van-search__content) {
    background: #ffffff;
    border: 2px solid #f3f3f3;
    padding: 0 12px 0 24px;
  }
  :v-deep(.van-search__field) {
    // height: 52px;
    font-size: 16px;
    color: #b2b2b2;
  }
}
</style>
